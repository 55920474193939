<template>
  <div
    v-if="breadcrumbList"
    class="w-full py-2 flex items-end text-md text-left"
  >
    <a-icon
      type="home"
      theme="filled"
      class="my-auto mx-2"
      :style="{ fontSize: '14px', color: '#415161' }"
    />
    <div
      class="text-xs md:text-sm mr-1"
      v-for="(breadcrumb, index) in breadcrumbList"
      :key="index"
    >
      <span
        :class="
          index != Object.keys(breadcrumbList).length - 1
            ? ''
            : 'font-semibold text-blue'
        "
      >
        <router-link v-if="breadcrumb.link" :to="breadcrumb.link">{{
          breadcrumb.label
        }}</router-link>
        <span v-else :to="breadcrumb.link">{{ breadcrumb.label }}</span>
        <span v-if="breadcrumb.param">
          {{ $route.query[breadcrumb.param] }}</span
        >
      </span>
      <span
        v-if="index != Object.keys(breadcrumbList).length - 1"
        class="mx-px"
      >
        /
      </span>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.initBreadcrumb();
    this.updated();
  },
  data() {
    return {
      breadcrumbList: [],
    };
  },
  methods: {
    initBreadcrumb() {
      if (
        this.$route.fullPath === "/" ||
        this.$route.fullPath === "/dashboard/home"
      )
        this.breadcrumbList = [{ label: "Inicio", link: "/" }];
      else {
        let breadcrumbListSession =
          this.$store.getters["session/getBreadcrumb"];
        if (
          breadcrumbListSession &&
          breadcrumbListSession[breadcrumbListSession?.length - 1].link ===
            this.$route.fullPath
        )
          this.breadcrumbList = breadcrumbListSession;
        else this.breadcrumbList = this.$route?.meta?.breadcrumb;

        if (this.breadcrumbList)
          this.breadcrumbList[this.breadcrumbList?.length - 1] = {
            label: this.breadcrumbList[this.breadcrumbList?.length - 1].label,
            link: this.$route.fullPath,
          };
      }
    },
    splitBreadcrumb() {
      let actualBreadcrumbList = [...this.$route?.meta?.breadcrumb];
      let newBreadcrumbList = [];
      if (this.breadcrumbList.length > 0 && actualBreadcrumbList.length > 0) {
        for (let index = 0; index < actualBreadcrumbList.length; index++) {
          if (
            actualBreadcrumbList[index].label ===
            this.breadcrumbList[index]?.label
          )
            newBreadcrumbList.push(this.breadcrumbList[index]);
          else
            newBreadcrumbList.push({
              label: actualBreadcrumbList[index].label,
              link: this.$route?.fullPath,
            });
        }
      }
      this.breadcrumbList = newBreadcrumbList;
    },
    updated() {
      this.$store.dispatch("session/updateBreadcrumb", this.breadcrumbList);
    },
    buildBreadcrumb() {
      if (this.$route.meta.breadcrumb) {
        let actualBreadcrumbList = [...this.$route?.meta?.breadcrumb];

        if (
          this.$route.fullPath === "/" ||
          this.$route.fullPath === "/dashboard/home" ||
          this.$route.fullPath === "/customer"
        ) {
          this.breadcrumbList = [{ label: "Inicio", link: "/" }];
        } else {
          let breadcrumb = {
            label: actualBreadcrumbList[actualBreadcrumbList.length - 1].label,
            link: this.$route.fullPath,
          };
          if (
            this.breadcrumbList[this.breadcrumbList.length - 1].label ===
            breadcrumb.label
          )
            this.breadcrumbList[this.breadcrumbList.length - 1] = breadcrumb;
          else this.breadcrumbList.push(breadcrumb);
          this.splitBreadcrumb();
        }
      } else this.breadcrumbList = null;
    },
  },
  destroyed() {
    this.updated();
  },

  watch: {
    $route() {
      this.buildBreadcrumb();
      this.updated();
    },
  },
};
</script>

<style></style>